import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37691cdc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-organizations-root-list" }
const _hoisted_2 = { class: "box" }
const _hoisted_3 = { class: "box__headline" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "action" }
const _hoisted_6 = {
  key: 0,
  class: "box__list"
}
const _hoisted_7 = { class: "item__avatar" }
const _hoisted_8 = { class: "item__content" }
const _hoisted_9 = { class: "item__content-name" }
const _hoisted_10 = { class: "item__content-address" }
const _hoisted_11 = { class: "item__action" }
const _hoisted_12 = {
  key: 1,
  class: "box__message"
}
const _hoisted_13 = {
  key: 2,
  class: "box__loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppAdministratorOrganizationsRootListFormCreateIndexVue = _resolveComponent("AppAdministratorOrganizationsRootListFormCreateIndexVue")!
  const _component_OfficeBuilding = _resolveComponent("OfficeBuilding")!
  const _component_Right = _resolveComponent("Right")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_LoadingIndexVue = _resolveComponent("LoadingIndexVue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.translation.organizations), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_AppAdministratorOrganizationsRootListFormCreateIndexVue, { onGetUserOrganizationsManagement: _ctx.getUserOrganizationsManagement }, null, 8, ["onGetUserOrganizationsManagement"])
        ])
      ]),
      (_ctx.isDataLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizations, (organization) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "item",
                key: organization.id
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_OfficeBuilding)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, _toDisplayString(organization.content.name), 1),
                  _createElementVNode("div", _hoisted_10, _toDisplayString(organization.content.address) + ", " + _toDisplayString(organization.content.place) + ", " + _toDisplayString(organization.content.country), 1)
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_router_link, {
                    to: { name: 'AppAdministratorOrganizationsDetailsRootIndexVue', params: { id: organization.id } }
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Right)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.isDataLoadedAndEmpty)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.translation.noData), 1))
        : _createCommentVNode("", true),
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createVNode(_component_LoadingIndexVue)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}