
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppOrganizationIndex} from "@/models/app/organization";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import {OfficeBuilding, Right} from "@element-plus/icons-vue";
import AppAdministratorOrganizationsRootListFormCreateIndexVue from "@/views/app/administrator/organizations/root/list/form/create.vue";
import LoadingIndexVue from "@/components/loading.vue";

// Services
import {getRequest} from "@/services/api/request";
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    ElRow,
    ElCol,
    OfficeBuilding,
    Right,
    AppAdministratorOrganizationsRootListFormCreateIndexVue,
    LoadingIndexVue,
  },
})
export default class AppAdministratorOrganizationsRootListIndexVue extends Vue {
  isLoading = false;

  organizations: Array<AppOrganizationIndex> = [];

  get isDataLoaded(): boolean {
    return this.organizations.length > 0 && this.isLoading === false;
  }

  get isDataLoadedAndEmpty(): boolean {
    return this.organizations.length === 0 && this.isLoading === false;
  }

  get translation(): any {
    return getTranslation([
      "noData",
      "organizations",
    ]);
  }

  async getUserOrganizationsManagement(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/user/organizations/management`,
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.organizations = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    await this.getUserOrganizationsManagement();
  }
}
